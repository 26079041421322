/* colours */
$c-bg: #f3f3fa;
$c-text: #475569;
$c-text-light: #64748b;
$c-text-secondary: #858992;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #1949a3;
$c-dark: #1f2937;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #d73a17;

$c-red: #E25003;
$c-green: #10b981;
$c-blue: #73b0f4;
$c-darkblue: #3a4488;
$c-orange: #f0aa61;
$c-tint: #fbfbff;
$c-darkpurple: #556cb6;
