/*--------------------------------

Nucleo icon font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
  font-family: "nucleo";
  src: url("nucleo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* base class */
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 "Nucleo";
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.icon-is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.icon-rotate-90 {
  transform: rotate(90deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-y {
  transform: scaleY(-1);
}

.icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
.icon-animals::before {
  content: "\ea01";
}

.icon-back::before {
  content: "\ea02";
}

.icon-books::before {
  content: "\ea03";
}

.icon-calendar::before {
  content: "\ea04";
}

.icon-call::before {
  content: "\ea05";
}

.icon-communication::before {
  content: "\ea06";
}

.icon-doctor_visit::before {
  content: "\ea07";
}

.icon-documentation::before {
  content: "\ea08";
}

.icon-family::before {
  content: "\ea09";
}

.icon-family-2::before {
  content: "\ea0a";
}

.icon-folder::before {
  content: "\ea0b";
}

.icon-games::before {
  content: "\ea0c";
}

.icon-healthy_snack::before {
  content: "\ea0d";
}

.icon-home::before {
  content: "\ea0e";
}

.icon-hygiene::before {
  content: "\ea0f";
}

.icon-logout::before {
  content: "\ea10";
}

.icon-meal::before {
  content: "\ea11";
}

.icon-media::before {
  content: "\ea12";
}

.icon-medicines::before {
  content: "\ea13";
}

.icon-movie::before {
  content: "\ea14";
}

.icon-music::before {
  content: "\ea15";
}

.icon-nature::before {
  content: "\ea16";
}

.icon-news::before {
  content: "\ea17";
}

.icon-nurse::before {
  content: "\ea18";
}

.icon-other::before {
  content: "\ea19";
}

.icon-person::before {
  content: "\ea1a";
}

.icon-sleep::before {
  content: "\ea1b";
}

.icon-toilet::before {
  content: "\ea1c";
}

.icon-training::before {
  content: "\ea1d";
}

.icon-tv::before {
  content: "\ea1e";
}

.icon-walk::before {
  content: "\ea1f";
}

.icon-weight::before {
  content: "\ea20";
}
